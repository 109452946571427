import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Las 500 Preguntas Imprescindibles para acceder a la Policía Municipal de Madrid
                    2024
                </Title>
                <Text>
                    <p className="text-red-600 bg-red-200 rounded-md border-red-600 p-5 font-semibold text-sm">
                        * Los envíos se realizarán a partir de la semana del 12 de febrero *
                    </p>
                    <br />
                    Este libro surge como una herramienta innovadora destinada a facilitar la
                    preparación para la prueba de temario de la oposición al cuerpo de Policía
                    Municipal de Madrid. Su enfoque se centra en quinientas preguntas esenciales del
                    temario, proporcionando una única respuesta para cada una de ellas. La
                    estructura de la obra se caracteriza por ofrecer, además, una ampliación de
                    información detallada en las preguntas consideradas más relevantes.
                    <br />
                    <br />
                    La organización del libro sigue un criterio cuidadoso, clasificando las
                    preguntas de acuerdo con los temas establecidos en la convocatoria oficial. Esta
                    disposición temática facilita la comprensión y el estudio, permitiendo a los
                    aspirantes abordar de manera efectiva cada área del temario.
                    <br />
                    <br />
                    Como valor añadido, el libro ofrece a los lectores acceso gratuito durante un
                    año al módulo específico en la aplicación iPolicíaMadrid. Esta plataforma
                    complementaria proporciona recursos interactivos y herramientas adicionales para
                    fortalecer la preparación de los candidatos.
                    <br />
                    <br />
                    En resumen, este libro no solo se presenta como una guía exhaustiva para la
                    preparación de la oposición al cuerpo de Policía Municipal de Madrid, sino que
                    también incorpora elementos tecnológicos para maximizar la eficacia del estudio.
                    Con su enfoque claro y su disposición organizada, se convierte en una
                    herramienta valiosa para aquellos que buscan alcanzar el éxito en esta exigente
                    prueba.
                </Text>

                {/** Preview del libro */}
                <div className="container lg:px-0 py-20">
                    <Title>Vista previa del libro</Title>
                    <Text className="text-justify">
                        A continuación os adjuntamos un resumen en formato pdf del contenido del
                        libro.
                    </Text>

                    <a
                        href={require("@static/documents/products/523/preview.pdf")}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar vista previa
                    </a>
                    <br />
                    <br />

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/523/preview.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
